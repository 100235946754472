import { IHistoryStylePack } from "types";

const getTransactionIdsByStatuses = (groups: IHistoryStylePack[], statuses: string[]): string[] => {
  const stylePackIds: string[] = [];

  groups.forEach(group => {
    if (statuses.includes(group.status)) {
      stylePackIds.push(group.transaction_id);
    }
  });

  return stylePackIds;
};

export default getTransactionIdsByStatuses;

import { View } from '@picsart/ds-components/View';
import { Image } from '@picsart/ds-components/Image';
import { useStyles } from './HeaderStyles';
import { GenerationFlowPagesEnum, NavigationItems } from 'constants/constants';
import { INavigationItemsType } from 'types';
import { Text } from '@picsart/ds-components/Text';
import { Typography } from '@picsart/ds-foundation/typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCompanySymbol } from '@picsart/ds-foundation/Icons/IconCompanySymbol';
import { IconBurger } from '@picsart/ds-foundation/Icons/IconBurger';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { Accents } from '@picsart/ds-foundation/colors/accents';
import { dispatch } from 'store/external';
import mainSlice from 'store/slices/main';
import generationFlowSlice from 'store/slices/generationFlow';

const LeftSideHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const isNavigationItemActive = (navigationItemUrl: string) => location.pathname === navigationItemUrl;

  const handleNavigationClick = (navigationItemUrl: string) => {
    if (navigationItemUrl === '/') {
      dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    }
    navigate(navigationItemUrl);
  };

  const handleOpenMobileNavigation = () => dispatch(mainSlice.actions.setMobileNavigationStatus(true));

  return (
    <>
      <View className={`${classes.leftSide} ${classes.leftSideMobileOrTablet}`}>
        <IconBurger fill={Texts.base} className={classes.menuIcon} onClick={handleOpenMobileNavigation} />
        <IconCompanySymbol fill={Accents.primary.base.default} className={classes.mobileLogo} />
      </View>

      <View className={`${classes.leftSide} ${classes.leftSideDesktopOrLaptop}`}>
        <Image src='./Picsart_logo.svg' />
        <View className={classes.navigation}>
          {NavigationItems.map((navigationItem: INavigationItemsType) => (
            <Text
              key={navigationItem.url}
              onClick={() => handleNavigationClick(navigationItem.url)}
              className={`${classes.navigationItem} ${isNavigationItemActive(navigationItem.url) && classes.activeNavigationItem}`}
              size={Typography.t4}
            >
              {navigationItem.title}
            </Text>
          ))}
        </View>
      </View>
    </>
  );
};

export default LeftSideHeader;

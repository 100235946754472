import { View } from '@picsart/ds-components/View';
import { useStyles } from './StylesSelectionStyles';
import Title from 'components/Title/Title';
import { IReduxState, ISelectedStyle, IStyleType } from 'types';
import { getSelectedCategory, getSelectedStyles, getStyles } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { Spacings } from '@picsart/ds-foundation/spacings';
import ShortDescription from 'components/ShortDescription/ShortDescription';
import Style from './Style';
import { Grid } from '@picsart/ds-components/Grid';
import { useCallback, useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';

const stylesSelectionSelector = (state: IReduxState) => ({
  styles: getStyles(state),
  selectedStyles: getSelectedStyles(state),
  selectedCategory: getSelectedCategory(state),
});

const StylesSelection = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const { styles, selectedStyles, selectedCategory } = useSelector(stylesSelectionSelector);
  const [styleColumns, setStyleColumns] = useState<string>(`repeat(5, calc(20% - ${Spacings.s32}))`);

  const isStyleSelected = useCallback(
    (style: IStyleType) => {
      if (selectedStyles) {
        return selectedStyles.some((selectedStyle: ISelectedStyle) => selectedStyle.id === style.id);
      } else {
        return false;
      }
    },
    [selectedStyles]
  );

  const handleSelectStyle = useCallback((style: IStyleType) => {
    const selectedStyle: ISelectedStyle = {
      id: style.id,
      title: style.attributes.title,
      prompt: style.attributes.prompt,
      negative_prompt: style.attributes.negative_prompt,
      model: style.attributes.model_type.data.attributes.Key,
    };

    dispatch(generationFlowSlice.actions.setSelectedStyles([selectedStyle]));
  }, []);

  const getStyleColumns = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setStyleColumns(`repeat(3, calc(33% - ${Spacings.s16}))`);
    } else if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setStyleColumns(`repeat(4, calc(25% - ${Spacings.s16}))`);
    } else {
      setStyleColumns(`repeat(5, calc(20% - ${Spacings.s32}))`);
    }
  };

  useEffect(() => {
    getStyleColumns();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.root}>
      <Title content='2. Explore our styles' />
      <View marginBlockStart={Spacings.s16}>
        <ShortDescription content='Discover and pick a style to generate 10 trending avatars.' />
      </View>
      <Grid className={classes.styles} columns={styleColumns}>
        {styles &&
          styles.map(
            (style: IStyleType) =>
              style.attributes.type?.data?.attributes.key === selectedCategory && (
                <Style handleSelect={() => handleSelectStyle(style)} key={style.id} style={style.attributes} isSelected={isStyleSelected(style)} />
              )
          )}
      </Grid>
    </View>
  );
};

export default StylesSelection;

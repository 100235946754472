import { Spacings } from '@picsart/ds-foundation/spacings';
import { createUseStyles } from 'react-jss';
import { MEDIA_QUERY } from 'constants/constants';

export const useStyles = createUseStyles({
  packGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: Spacings.s16,
    marginTop: Spacings.s16,
    width: '100%',
    height: 'auto',
  },
  [MEDIA_QUERY.TABLET]: {
    packGridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: Spacings.s16,
      marginTop: Spacings.s16,
      width: '100%',
      height: 'auto',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    packGridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: Spacings.s16,
      marginTop: Spacings.s16,
      width: '100%',
      height: 'auto',
    },
  },
});

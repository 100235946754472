import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: Spacings.s40,
  },
  title: {
    textAlign: TypographyHorizontalAlign.Center,
    fontWeight: FontWeights.SemiBold,
  },
  categoryList: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: Spacings.s16,
  },
});

import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';
import { IGenerationPostData } from 'types';

export const postGenerationData = async (data: IGenerationPostData) => {
  try {
    const response = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/gen/group`, data);
    return response;
  } catch (error) {
    console.error('Failed to fetch categories', error);
    return error;
  }
};

import { View } from '@picsart/ds-components/View';
import { useStyles } from './ImageListStyles';
import { Image } from '@picsart/ds-components/Image';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ScrollBox, ScrollBoxVariants } from '@picsart/ds-components/ScrollBox';
import { Grid } from '@picsart/ds-components/Grid';
import { Spacings } from '@picsart/ds-foundation/spacings';

interface Props {
  imagePathList: string[];
  basePath: string;
}

const RulesImageList = ({ imagePathList, basePath }: Props) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <>
      <View className={classes.root}>
        {imagePathList.map((imagePath: string) => (
          <Image key={imagePath} alt={imagePath} className={classes.image} src={`${basePath}${imagePath}`} />
        ))}
      </View>
      <ScrollBox variant={ScrollBoxVariants.X} key={width} width={width} height={150} className={classes.scrollBox}>
        <Grid className={classes.imageList} columns={`repeat(5, calc(20% - ${Spacings.s12}))`}>
          {imagePathList.map((imagePath: string) => (
            <View className={classes.imageView} key={imagePath}>
              <Image key={imagePath} alt={imagePath} src={`${basePath}${imagePath}`} />
            </View>
          ))}
        </Grid>
      </ScrollBox>
    </>
  );
};

export default RulesImageList;

import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: Spacings.s16,
  },
  title: {
    fontWeight: FontWeights.SemiBold,
    textAlign: TypographyHorizontalAlign.Center,
  },
  description: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
  },
  thumbnail: {
    width: '160px',
    height: '160px',
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
  },
  thumbnailTitle: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
  },
});

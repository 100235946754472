import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader, LoaderSizeLG } from '@picsart/ds-components/Loader';
import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { Typography } from '@picsart/ds-foundation/typography';
import { dispatch } from 'store/external';
import historySlice from 'store/slices/history';
import { getHistoryStylePacks } from 'store/slices/history/selectors';
import { getHistory } from 'api/server/getHistory';
import { useStyles } from './HistoryStyles';
import EmptyHistory from './EmptyHistory/EmptyHistoryPlaceholder';
import HistoryPacks from './HistoryPacks/HistoryPacks';
import { IHistoryStylePack, IReduxState } from 'types';
import { AvatarGenerationStatus } from 'constants/constants';
import getTransactionIdsByStatuses from 'utils/getTransactionIdsByStatuses';
import getGeneratedStyle from 'api/server/getGeneratedStyle';

const historySelector = (state: IReduxState) => ({
  historyStylePacks: getHistoryStylePacks(state) || [],
});

const History = () => {
  const [isFetchingBegan, setIsFetchingBegan] = useState(false);
  const [inProgressAvatarTransactionIds, setinProgressAvatarTransactionIds] = useState<string[]>([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [results, setResults] = useState<IHistoryStylePack[]>([]);

  const activeTimeouts = new Map<string, NodeJS.Timeout>();

  const { historyStylePacks } = useSelector(historySelector);

  const classes = useStyles();

  useEffect(() => {
    if (!historyStylePacks.length) {
      setIsHistoryLoading(true);
    }
    getHistory()
      .then(res => {
        dispatch(historySlice.actions.setHistory(res.data.data));
        const inProgressTransactionIds = getTransactionIdsByStatuses(res.data.data, [
          AvatarGenerationStatus.ACCEPTED,
          AvatarGenerationStatus.IN_PROGRESS,
        ]);
        setinProgressAvatarTransactionIds(inProgressTransactionIds);
      })
      .catch(() => {})
      .finally(() => {
        setIsHistoryLoading(false);
      });
  }, []);

  const startStatusFetching = useCallback(async (ids: string[]) => {
    await Promise.all(ids.map(id => fetchResults(id)));
  }, []);

  useEffect(() => {
    if (inProgressAvatarTransactionIds.length > 0 && !isFetchingBegan) {
      setIsFetchingBegan(true);
      startStatusFetching(inProgressAvatarTransactionIds)
        .then(() => {})
        .catch(() => {
          setIsFetchingBegan(false);
        });
    }
  }, [historyStylePacks, isFetchingBegan, inProgressAvatarTransactionIds, startStatusFetching]);

  const fetchHistory = async () => {
    getHistory()
      .then(res => {
        dispatch(historySlice.actions.setHistory(res.data.data));
      })
      .catch(() => {})
      .finally(() => {});
  };

  const fetchResults = async (id: string) => {
    getGeneratedStyle(id)
      .then(resp => {
        if (resp?.data) {
          const isDone = resp.data.status === AvatarGenerationStatus.DONE;
          const isImageArrayEmpty = resp.data.images.length === 0;
          const isInProgress = resp.data.status === AvatarGenerationStatus.IN_PROGRESS;
          const isAccepted = resp.data.status === AvatarGenerationStatus.ACCEPTED;

          setinProgressAvatarTransactionIds(prev => {
            // If status is IN_PROGRESS or ACCEPTED or DONE and Images array is empty, add the ID to inProgressIds
            if (isInProgress || isAccepted || (isDone && isImageArrayEmpty)) {
              if (activeTimeouts.has(id)) {
                const timeout = activeTimeouts.get(id);
                if (timeout !== undefined) {
                  clearTimeout(timeout);
                }
              }
              // Set a new timeout and store it in the map
              const timeout = setTimeout(() => {
                fetchResults(id)
                  .then(() => {})
                  .catch(() => {});
              }, 15000);
              activeTimeouts.set(id, timeout);

              if (!prev.includes(resp.data.transaction_id)) {
                return [...prev, resp.data.transaction_id];
              }
              return prev;
            }
            fetchHistory()
              .then(() => {})
              .catch(() => {});
            // Remove the ID from inProgressIds if the status is not IN_PROGRESS
            return prev.filter(styleId => styleId !== resp.data.transaction_id);
          });

          setResults(prev => {
            // Find the index of the existing item
            const existingIndex = prev.findIndex(item => item.transaction_id === resp.data.transaction_id);

            if (existingIndex !== -1) {
              // Replace the existing item
              const updatedResults = [...prev];
              updatedResults[existingIndex] = resp.data;
              return updatedResults;
            }
            // Add the new item
            return [...prev, resp.data];
          });
        }
      })
      .catch(() => {
        // Handle errors (optional)
      });
  };

  return isHistoryLoading ? (
    <View className={classes.root}>
      <Loader size={LoaderSizeLG} isWithoutBackground />
    </View>
  ) : historyStylePacks && Array.isArray(historyStylePacks) ? (
    historyStylePacks.length > 0 ? (
      <View flexDirection="column" marginBlockStart={Spacings.s32} height={'100%'}>
        <Text weight={FontWeights.SemiBold} size={Typography.t7}>
          My avatars gallery
        </Text>
        <View marginBlockStart={Spacings.s16} marginBlockEnd={Spacings.s16}>
          <HistoryPacks />
        </View>
      </View>
    ) : (
      <EmptyHistory />
    )
  ) : (
    <EmptyHistory />
  );
};

export default History;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAuthAvatar, IDiscordAuthenthication } from 'types';
import initialState from './initialState';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, { payload }: PayloadAction<IDiscordAuthenthication | null>) {
      state.user = payload;
    },
    setUserAvatarUrl(state, { payload }: PayloadAction<IAuthAvatar>) {
      state.avatar = payload;
    },
    setUserAccessToken(state, { payload }: PayloadAction<string | null>) {
      state.access_token = payload;
    },
    setUserRefreshToken(state, { payload }: PayloadAction<string | null>) {
      state.refresh_token = payload;
    }
  },
});

export default authSlice;

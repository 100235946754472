import { Spacings } from '@picsart/ds-foundation/spacings';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  warningWrapper: {
    top: Spacings.s0,
    bottom: Spacings.s0,
    right: Spacings.s0,
    left: Spacings.s0,
  },
});

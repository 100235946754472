import { FreeAvatarGenerationFlowPagesEnum, GenerationFlowPagesEnum, GenerationFlowTypesEnum } from 'constants/constants';
import { IGenerationFlowState } from 'types';

const initialState: IGenerationFlowState = {
  currentPage: GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES,
  freeAvatarGenerationCurrentPage: FreeAvatarGenerationFlowPagesEnum.FREE_GENERATION_INTRO,
  flowType: GenerationFlowTypesEnum.AVATAR_GENERATION_WITH_PAYMENT,
  categories: null,
  freeAvatarCategory: null,
  selectedCategory: null,
  styles: null,
  selectedStyles: null,
  freeAvatarStyle: null,
  isTermsChecked: false,
  uploadedImage: null,
};

export default initialState;

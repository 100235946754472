import { Toaster } from '@picsart/ds-components/Toaster';
import { Snackbar, SnackbarSkins } from '@picsart/ds-components/Snackbar';
import { ElementType } from 'react';

interface Props {
  handleCloseNotification: () => void;
  isOpen: boolean;
  description: string;
  icon: ElementType;
  skin: SnackbarSkins;
}

const Notification = ({ handleCloseNotification, isOpen, description, skin, icon }: Props) => {
  return (
    <Toaster isOpen={isOpen} onClose={handleCloseNotification} zIndex={2} width={400}>
      <Snackbar title={description} actionIcon={icon} skin={skin} handleClose={handleCloseNotification} />
    </Toaster>
  );
};

export default Notification;

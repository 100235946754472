import axios from 'axios';
import {
  DISCORD_PROXY_BASE_URL,
  ImageFaceCheckStatusEnum,
} from 'constants/constants';

export const getImageFaceCheckStatus = async (imageUrl: string) => {
  try {
    const response = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/media/face-check`, {
      image_url: imageUrl,
    });

    return response.data.data.status;
  } catch (error) {
    console.error('Failed to fetch categories', error);
    return ImageFaceCheckStatusEnum.NOT_VALID;
  }
};

import { View } from '@picsart/ds-components/View';
import { useStyles } from './EmptyHistoryPlaceholderStyles';
import { IconAIAvatar } from '@picsart/ds-foundation/Icons/IconAIAvatar';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { Typography } from '@picsart/ds-foundation/typography';
import { Text } from '@picsart/ds-components/Text';
import { Button, ButtonSizeMD, ButtonVariants } from '@picsart/ds-components/Button';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { GenerationFlowPagesEnum } from 'constants/constants';

const EmptyHistory = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToMainPage = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    navigate('/');
  };

  return (
    <View className={classes.root}>
      <View className={classes.icon}>
        <IconAIAvatar fill={Texts.base} />
      </View>
      <Text className={classes.title} size={Typography.t6}>
        Explore different versions of yourself with AI Avatar
      </Text>
      <View className={classes.descriptionBox}>
        <Text className={classes.descriptionItem} size={Typography.t5}>
          Channel your main character energy by turning into a fantasy epic hero or
        </Text>
        <Text className={classes.descriptionItem} size={Typography.t5}>
          a cute cartoon persona and embark on exciting adventures.
        </Text>
      </View>
      <Button className={classes.button} variant={ButtonVariants.Outline} size={ButtonSizeMD} onClick={navigateToMainPage}>
        Create avatars
      </Button>
    </View>
  );
};

export default EmptyHistory;

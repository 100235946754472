import { View } from '@picsart/ds-components/View';
import { useStyles } from './GenerationFlowRootStyles';
import GenderSelection from 'components/GenderSelection/GenderSelection';
import StylesSelection from 'components/StylesSelection/StylesSelection';
import { Button } from '@picsart/ds-components/Button';
import { IReduxState } from 'types';
import { getSelectedStyles } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { GenerationFlowPagesEnum, GenerationFlowTypesEnum } from 'constants/constants';

const generationFlowRootSelector = (state: IReduxState) => ({
  selectedStyles: getSelectedStyles(state),
});

const GenerationFlowRoot = () => {
  const classes = useStyles();
  const { selectedStyles } = useSelector(generationFlowRootSelector);

  const handlePageChange = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.TERMS_OF_USE_AND_IMAGE_UPLOAD));
    dispatch(generationFlowSlice.actions.setGenerationFlowType(GenerationFlowTypesEnum.AVATAR_GENERATION_WITH_PAYMENT));
  };

  return (
    <View className={classes.root}>
      <GenderSelection />
      <StylesSelection />
      <View className={classes.buttonBox}>
        <Button className={classes.button} isDisabled={!selectedStyles || selectedStyles.length === 0} onClick={handlePageChange}>
          Create avatars
        </Button>
      </View>
    </View>
  );
};

export default GenerationFlowRoot;

import { Spacings } from '@picsart/ds-foundation/spacings';
import { Timings } from '@picsart/ds-foundation/timings';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: Spacings.s16,
    background: 'linear-gradient(#ffffff00, #ffffff)',
    width: '100%',
    position: 'sticky',
    bottom: Spacings.s0,
    left: Spacings.s0,
    right: Spacings.s0,
  },
  button: {
    minWidth: '220px !important',
    transition: Timings.Default,
  },
});

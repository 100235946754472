import { View } from '@picsart/ds-components/View';
import { useStyles } from './PreviewStyles';
import ToggleButtons from 'components/ToggleButtons/ToggleButtons';
import { HowItWorksDefaultParams } from 'constants/constants';
import { useEffect, useState } from 'react';
import { IHowItWorksStyles, IHowItWorksStylesImages } from 'types';
import PreviewImageList from './PreviewImageList/PreviewImageList';

const Preview = () => {
  const classes = useStyles();

  const [selectedStyleKey, setSelectedStyleKey] = useState<string>(HowItWorksDefaultParams.styles[0].key);

  const [selectedStyleImageUrls, setSelectedStyleImageUrls] = useState<IHowItWorksStylesImages | null>(null);

  const getSelectedStyleImageUrls = (selectedStyleKey: string) => {
    const selectedStyle = HowItWorksDefaultParams.styles.find((style: IHowItWorksStyles) => style.key === selectedStyleKey);

    if (selectedStyle) {
      setSelectedStyleImageUrls(selectedStyle.images);
    } else {
      setSelectedStyleImageUrls(null);
    }
  };

  useEffect(() => {
    getSelectedStyleImageUrls(selectedStyleKey);
  }, [selectedStyleKey]);

  return (
    <View className={classes.root}>
      <ToggleButtons buttons={HowItWorksDefaultParams.styles} currentActive={selectedStyleKey} handleChange={(buttonKey: string) => setSelectedStyleKey(buttonKey)} />
      {selectedStyleImageUrls && <PreviewImageList selectedStyleImageUrls={selectedStyleImageUrls} style={selectedStyleKey} />}
    </View>
  );
};

export default Preview;

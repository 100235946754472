import { combineReducers } from '@reduxjs/toolkit';
import authSlice from 'store/slices/auth';
import generationFlowSlice from 'store/slices/generationFlow';
import historySlice from './slices/history';
import mainSlice from './slices/main';

const reducer = combineReducers({
  auth: authSlice.reducer,
  generationFlow: generationFlowSlice.reducer,
  history: historySlice.reducer,
  main: mainSlice.reducer,
});

export default reducer;

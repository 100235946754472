import { Avatar } from '@picsart/ds-components/Avatar';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { View } from '@picsart/ds-components/View';
import { DEVICE_SCREEN_MAXIMUM_SIZES, FreeAvatarGenerationFlowPagesEnum, GenerationFlowPagesEnum } from 'constants/constants';
import { useSelector } from 'react-redux';
import { getUserAvatar, getUserData } from 'store/slices/auth/selectors';
import { IReduxState } from 'types';
import { useStyles } from './HeaderStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonSizeMD, ButtonSkinMarket } from '@picsart/ds-components/Button';
import { IconAIAvatar } from '@picsart/ds-foundation/Icons/IconAIAvatar';
import { useEffect, useState } from 'react';
import FreeAvatarPopUp from 'components/FreeAvatar/FreeAvatarPopUp';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { getCDNURL } from 'utils/getCDNUrl';
import useWindowDimensions from 'hooks/useWindowDimensions';

const rightSideHeaderSelector = (state: IReduxState) => ({
  avatar: getUserAvatar(state),
  user: getUserData(state),
});

const RightSideHeader = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { avatar, user } = useSelector(rightSideHeaderSelector);
  const { width } = useWindowDimensions();

  const [freeAvatarPopUpIsVisible, setFreeAvatarPopUpVisiblity] = useState<boolean>(false);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  const handleChangeFreeAvatarPopUpStatus = () => {
    setFreeAvatarPopUpVisiblity(!freeAvatarPopUpIsVisible);
    dispatch(generationFlowSlice.actions.setFreeAvatarCurrentPage(FreeAvatarGenerationFlowPagesEnum.FREE_GENERATION_INTRO));
  };

  const getAvatarUrl = () => {
    return user?.avatar ? (avatar.id ? getCDNURL(avatar) : avatar.url) : null;
  };

  const isCreateButtonActive = () => location.pathname !== '/';
  const handleCreateButtonClick = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    navigate('/');
  };

  const getWindowSize = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  };

  useEffect(() => {
    getWindowSize();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.rightSide}>
      <Avatar
        imageSrc={getAvatarUrl()}
        imageAlt='User Avatar'
        className={classes.userAvatar}
        size={48}
        width={Spacings.s48}
        height={Spacings.s48}
        style={{
          width: Spacings.s48,
          height: Spacings.s48,
        }}
      />
      <View className={classes.actions}>
        {isCreateButtonActive() && !isMobileDevice && (
          <Button onClick={handleCreateButtonClick} size={ButtonSizeMD} className={`${classes.button} ${classes.createAvatarButton}`}>
            Create avatars
          </Button>
        )}
        <Button size={ButtonSizeMD} endIcon={IconAIAvatar} skin={ButtonSkinMarket} className={classes.button} onClick={handleChangeFreeAvatarPopUpStatus}>
          Free avatar
        </Button>
      </View>
      <FreeAvatarPopUp isPopUpOpen={freeAvatarPopUpIsVisible} handleClose={handleChangeFreeAvatarPopUpStatus} />
    </View>
  );
};

export default RightSideHeader;

import { Spacings } from '@picsart/ds-foundation/spacings';
import { createUseStyles } from 'react-jss';
import { MEDIA_QUERY } from 'constants/constants';

export const useStyles = createUseStyles({
  root: {
    boxSizing: 'border-box',
    padding: `${Spacings.s24} 145px !important`,
  },
  header: { padding: `${Spacings.s0} 145px !important` },
  [MEDIA_QUERY.LAPTOP]: {
    root: { padding: `${Spacings.s24} 100px !important` },
    header: { padding: `${Spacings.s24} 100px !important` },
  },
  [MEDIA_QUERY.TABLET]: {
    root: { padding: `${Spacings.s24} ${Spacings.s40} !important` },
    header: { padding: `${Spacings.s24} ${Spacings.s40} !important` },
  },
  [MEDIA_QUERY.MOBILE]: {
    root: { padding: `${Spacings.s24} !important` },
    header: { padding: `${Spacings.s24} !important` },
  },
});

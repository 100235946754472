import { Button } from '@picsart/ds-components/Button';
import { useStyles } from './ImageUploadStyles';
import { View } from '@picsart/ds-components/View';
import { getIsTermsCheckedStatus } from 'store/slices/generationFlow/selectors';
import { IReduxState, IUploadedImage } from 'types';
import { useSelector } from 'react-redux';
import UploadPopUp from 'components/UploadPopUp/UploadPopUp';
import { useRef, useState } from 'react';
import { uploadImage } from 'api/server/uploadImage';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { Loader, LoaderVariants } from '@picsart/ds-components/Loader';
import Notification from 'components/Notification/Notification';
import { IconInfoCircleOutline } from '@picsart/ds-foundation/Icons/IconInfoCircleOutline';
import { SnackbarSkins } from '@picsart/ds-components/Snackbar';

const imageUploadSelector = (state: IReduxState) => ({
  isTermsChecked: getIsTermsCheckedStatus(state),
});

const ImageUpload = () => {
  const classes = useStyles();
  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const { isTermsChecked } = useSelector(imageUploadSelector);

  const [uploadPopUpIsVisible, setUploadPopUpVisibility] = useState<boolean>(false);
  const [uploadLoadingIsVisisble, setUploadLoadingVisibility] = useState<boolean>(false);
  const [uploadErrorIsVisible, setUploadErrorVisisbility] = useState<boolean>(false);

  const toggleOverlay = () => setUploadPopUpVisibility(!uploadPopUpIsVisible);
  const handleCloseUploadError = () => setUploadErrorVisisbility(false);

  const handleFileUpload = async (file: File) => {
    if (file) {
      setUploadLoadingVisibility(true);
      setUploadErrorVisisbility(false);
      const uploadedImageResponse: IUploadedImage = await uploadImage(file);

      if (uploadedImageResponse.success) {
        dispatch(
          generationFlowSlice.actions.setUploadedImage({
            url: uploadedImageResponse.url,
            id: uploadedImageResponse.id,
            faceCheckStatus: null,
          })
        );
        setUploadLoadingVisibility(false);
        toggleOverlay();
      } else {
        dispatch(
          generationFlowSlice.actions.setUploadedImage({
            url: null,
            id: null,
            faceCheckStatus: null,
          })
        );
        setUploadLoadingVisibility(false);
        setUploadErrorVisisbility(true);
      }
    }
  };

  const handleInputClick = () => {
    uploadButtonRef.current.value = null;
    uploadButtonRef.current.click();
  };

  return (
    <View>
      <Notification
        handleCloseNotification={handleCloseUploadError}
        isOpen={uploadErrorIsVisible}
        description={'Something went wrong. Please try again.'}
        icon={IconInfoCircleOutline}
        skin={SnackbarSkins.Error}
      />
      <Button isDisabled={!isTermsChecked} className={classes.button} onClick={handleInputClick}>
        Create avatars
        <input hidden ref={uploadButtonRef} accept='image/png, image/jpeg, image/jpg' type='file' onChange={(e) => handleFileUpload(e.target.files[0])} />
      </Button>
      {uploadPopUpIsVisible && <UploadPopUp isOpen={uploadPopUpIsVisible} handleClose={toggleOverlay} />}

      {uploadLoadingIsVisisble && <Loader variant={LoaderVariants.Global} zIndex={2} />}
    </View>
  );
};

export default ImageUpload;

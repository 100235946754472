import { View } from '@picsart/ds-components/View';
import { IToggleButton } from 'types';
import { useStyles } from './ToggleButtonsStyles';
import { Button, ButtonSizeMD, ButtonSkinAbsoluteBlack, ButtonSkinPrimary, ButtonVariants } from '@picsart/ds-components/Button';

interface Props {
  buttons: IToggleButton[];
  currentActive: string;
  handleChange: (buttonKey: string) => void;
}

const ToggleButtons = ({ buttons, currentActive, handleChange }: Props) => {
  const classes = useStyles();

  const isSelected = (button: IToggleButton) => {
    return currentActive === button.key;
  };

  return (
    <View className={classes.root}>
      {buttons.map((buttonItem: IToggleButton) => (
        <Button
          key={buttonItem.key}
          size={ButtonSizeMD}
          onClick={() => handleChange(buttonItem.key)}
          skin={isSelected(buttonItem) ? ButtonSkinPrimary : ButtonSkinAbsoluteBlack}
          variant={isSelected(buttonItem) ? ButtonVariants.Filled : ButtonVariants.Text}
        >
          {buttonItem.title}
        </Button>
      ))}
    </View>
  );
};

export default ToggleButtons;

import { ImageFaceCheckStepsEnum, ImageFaceCheckStepsOptions } from 'constants/constants';
import { useStyles } from './UploadPopUpStyles';
import { View } from '@picsart/ds-components/View';

interface Props {
  status: ImageFaceCheckStepsEnum;
}

const FaceCheckLoader = ({ status }: Props) => {
  const classes = useStyles();

  return (
    <View className={classes.overlay}>
      <View
        className={classes.overlayBackground}
        style={{
          opacity: ImageFaceCheckStepsOptions[status].opacity,
          background: ImageFaceCheckStepsOptions[status].bgColor,
        }}
      />
      {ImageFaceCheckStepsOptions[status].icon}
    </View>
  );
};

export default FaceCheckLoader;

import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const uploadImage = async (file: File) => {
  if (file) {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const { data } = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/media/image`, formData, { headers: { 'content-type': 'multipart/form-data' } });

      if (data.success) {
        return {
          success: data.success,
          id: data.sessionID,
          url: data.url,
        };
      } else {
        return {
          success: data.success,
          id: null,
          url: null,
        };
      }
    } catch (error) {
      console.error('Failed to upload image', error);
      return {
        success: false,
        id: null,
        url: null,
      };
    }
  }
};

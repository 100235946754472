/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';
import { IHistoryStylePack } from 'types';

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistory(state, { payload }: PayloadAction<IHistoryStylePack[]>) {
      state.historyStylePacks = payload;
    },
    setIsComingFromFreeAvatarCreation(state, { payload }: PayloadAction<boolean>) {
      state.isComingFromFreeAvatarCreation = payload;
    },
  },
});

export default historySlice;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';
import { IDiscordSDK } from '@discord/embedded-app-sdk';
import { IDiscordSKU } from 'types';

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setMobileNavigationStatus(state, { payload }: PayloadAction<boolean>) {
      state.mobileNavigationOpenStatus = payload;
    },
    setDiscordSDK(state, { payload }: PayloadAction<IDiscordSDK>) {
      state.discordSDK = payload as any;
    },
    setDiscordSKUs(state, { payload }: PayloadAction<IDiscordSKU[]>) {
      state.skus = payload;
    },
  },
});

export default mainSlice;

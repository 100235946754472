import { Spacings } from '@picsart/ds-foundation/spacings';
import { Timings } from '@picsart/ds-foundation/timings';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: Spacings.s4,
  },
  button: {
    minWidth: '220px',
    transition: Timings.Default,
  },
});

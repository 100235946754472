import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});

import { View } from '@picsart/ds-components/View';
import { Loader, LoaderVariants } from '@picsart/ds-components/Loader';
import { useStyles } from './AuthLoadingStyles';

const AuthLoading = () => {
  const classes = useStyles();

  return (
    <View className={classes.root}>
      <Loader variant={LoaderVariants.Global} />
    </View>
  );
};

export default AuthLoading;

import { createUseStyles } from 'react-jss';
import { MEDIA_QUERY } from 'constants/constants';

type Props = {
  isZoomedIn?: boolean,
  isFreeAvatarsHistoryEmpty?: boolean,
};

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
  },
  tabs: (props: Props = { isFreeAvatarsHistoryEmpty: false }) => {
    return {
      width: props.isFreeAvatarsHistoryEmpty ? '160px' : '320px',
    };
  },
  tab: {
    width: '160px',
  },
  tabpanel: {
    height: '100%',
    width: '100%',
  },
  [MEDIA_QUERY.TABLET]: {
    tabs: (props: Props = { isFreeAvatarsHistoryEmpty: false }) => {
      return {
        width: props.isFreeAvatarsHistoryEmpty ? '160px' : '320px',
      };
    },
    tab: {
      width: '160px',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    tabs: (props: Props = { isFreeAvatarsHistoryEmpty: false }) => {
      return {
        width: props.isFreeAvatarsHistoryEmpty ? '150px' : '320px',
      };
    },
    tab: {
      width: '150px',
    },
  },
});

import { View } from '@picsart/ds-components/View';
import { useStyles } from './StepStyles';
import { IHowItWorksStep } from 'types';
import { Text } from '@picsart/ds-components/Text';
import { Typography, ITypography } from '@picsart/ds-foundation/typography';
import { useEffect, useState } from 'react';
import { Button } from '@picsart/ds-components/Button';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DEVICE_SCREEN_MAXIMUM_SIZES, GenerationFlowPagesEnum } from 'constants/constants';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';

interface Props {
  step: IHowItWorksStep;
  index: number;
}

const Step = ({ step, index }: Props) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t6);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setTextSize(Typography.t5);
    } else {
      setTextSize(Typography.t6);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  const handleButtonClick = (url: string) => {
    if (url === '/') {
      dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    }
    navigate(url);
  };

  return (
    <View className={`${classes.root} ${isActive && classes.root_active}`} onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
      <Text className={`${classes.index} ${isActive && classes.index_active}`} size={isActive ? Typography.t11 : Typography.t8}>
        {index}
      </Text>
      <View className={classes.textView}>
        <Text className={`${classes.title} ${isActive && classes.title_active}`} size={textSize}>
          {step.title}
        </Text>
        <View className={`${classes.hidden_view} ${isActive && classes.hidden_view_active}`}>
          <Text className={classes.description} size={textSize}>
            {step.description}
          </Text>
          {step.button && (
            <Button onClick={() => handleButtonClick(step.button.url)} startIcon={step.button.icon} className={classes.button}>
              {step.button.title}
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};

export default Step;

import { useEffect, useState } from 'react';
import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Typography } from '@picsart/ds-foundation/typography';
import { Accents } from '@picsart/ds-foundation/colors/accents';
import { IconDownload } from '@picsart/ds-foundation/Icons/IconDownload';
import { Statuses } from '@picsart/ds-foundation/colors/statuses';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useStyles } from './GeneratedPackStyles';
import GeneratedImage from '../GeneratedImage/GeneratedImage';
import FailedGenerationMessage from '../FailedGenerationMessage/FailedGenerationMessage';
import ResultPreview from '../ResultPreview/ResultPreview';
import { AvatarGenerationStatus, DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';
import MobileResultPreview from '../MobileResultPreview/MobileResultPreview';
import useDownload from 'hooks/useDownload';
import { IHistoryStylePack } from 'types';

interface Props {
  data: IHistoryStylePack;
}

const GeneratedPack = ({ data }: Props) => {
  const [choosenImageIndex, setChoosenImageIndex] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const { width } = useWindowDimensions();
  const { downloadAll } = useDownload();
  const classes = useStyles();

  const handleImageClick = (index: number) => {
    setChoosenImageIndex(index);
    handlePreviewOpen();
  };

  useEffect(() => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, [width]);

  const handlePreviewOpen = () => {
    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const handleDownloadAll = () => {
    if (data?.images.length > 0) {
      const imageUrls = data.images.map(image => image.url);
      downloadAll(imageUrls);
    }
  };

  const isGenerationDone = data?.status === AvatarGenerationStatus.DONE;
  const isGenerationFailed =
    data?.status === AvatarGenerationStatus.FAILED ||
    data?.status === AvatarGenerationStatus.FAILED_IMAGE ||
    data?.status === AvatarGenerationStatus.FAILED_CREDIT;
  const isGenerationLoading =
    data?.status === AvatarGenerationStatus.IN_PROGRESS ||
    data?.status === AvatarGenerationStatus.ACCEPTED ||
    data?.status === AvatarGenerationStatus.INIT;

  const getErrorTitle = () => {
    if (data?.status === AvatarGenerationStatus.FAILED_CREDIT) {
      return 'Payment problem';
    } else {
      return 'Something went wrong';
    }
  };

  const getErrorDescription = () => {
    if (data?.status === AvatarGenerationStatus.FAILED) {
      return 'We couldn’t process your request. Reach out to the support team, and they’ll look into it further for you.';
    } else if (data?.status === AvatarGenerationStatus.FAILED_IMAGE) {
      return 'We couldn’t process your photo. Try again with a different snap. Don’t worry, you won’t be charged.';
    } else if (data?.status === AvatarGenerationStatus.FAILED_CREDIT) {
      return 'There was a problem processing your payment. Reach out to the Discord support team, and they’ll look into it further for you.';
    }
  };

  return (
    <View key={data.group_id} flexDirection="column" marginBlockStart={Spacings.s32} width="100%">
      {isPreviewOpen &&
        (isMobileDevice ? (
          <MobileResultPreview
            key={choosenImageIndex}
            isOpen={isPreviewOpen}
            images={data.images}
            onClose={handlePreviewClose}
            choosenImageIndex={choosenImageIndex}
          />
        ) : (
          <ResultPreview
            key={choosenImageIndex}
            images={data.images}
            isOpen={isPreviewOpen}
            onClose={handlePreviewClose}
            choosenImageIndex={choosenImageIndex}
          />
        ))}
      <View alignItems="center" justifyContent="space-between" width={'100%'}>
        <Text
          color={isGenerationFailed ? Statuses.error.base.default : Texts.base}
          size={Typography.t6}
          weight={FontWeights.Medium}
        >
          {data?.title} {isGenerationFailed && '(failed)'}
        </Text>
        {isGenerationLoading || isGenerationFailed || (
          <View
            className={classes.downloadAllWrapper}
            gap={Spacings.s8}
            alignItems="center"
            onClick={handleDownloadAll}
          >
            <Text color={Accents.secondary.base.active} weight={FontWeights.SemiBold} size={Typography.t4}>
              Download All
            </Text>
            <IconDownload fill={Accents.secondary.base.active} height={20} width={20} />
          </View>
        )}
      </View>
      <View position="relative">
        {isGenerationFailed && <FailedGenerationMessage description={getErrorDescription()} title={getErrorTitle()} />}
        <View className={classes.packGridContainer} width={'100%'}>
          {data?.images.length > 0 && isGenerationDone
            ? data.images.map((image, index) => (
                <GeneratedImage
                  key={image._id}
                  index={index}
                  image={image}
                  handleImageClick={handleImageClick}
                  isFailed={isGenerationFailed}
                  isLoading={isGenerationLoading}
                />
              ))
            : Array.from({ length: 10 }, (_, index) => (
                <GeneratedImage
                  key={index}
                  index={index}
                  image={null}
                  handleImageClick={handleImageClick}
                  isFailed={isGenerationFailed}
                  isLoading={isGenerationLoading || data?.images.length === 0}
                />
              ))}
        </View>
      </View>
    </View>
  );
};

export default GeneratedPack;

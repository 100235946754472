import HowItWorks from 'components/HowItWorks/HowItWorks';
import StandardWrapper from 'containers/StandardWrapper';
import { useEffect } from 'react';
import { windowScrollToTop } from 'utils/windowScrollToTop';

const HowItWorksWrapper = () => {
  useEffect(() => {
    windowScrollToTop(window);
  }, []);

  return <StandardWrapper content={<HowItWorks />} />;
};
export default HowItWorksWrapper;

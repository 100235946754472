import { Radio } from '@picsart/ds-components/Radio';
import { useStyles } from './CategoryBoxStyles';
import { ICategoryType } from 'types';
import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { Typography } from '@picsart/ds-foundation/typography';

interface Props {
  category: ICategoryType;
  isCategorySelected: boolean;
  handleCategorySelect: () => void;
}

const CategoryBox = ({ category, isCategorySelected, handleCategorySelect }: Props) => {
  const classes = useStyles();
  return (
    <View key={category.id} className={`${classes.category} ${isCategorySelected && classes.selectedCategory}`} onClick={handleCategorySelect}>
      <Radio readOnly isChecked={isCategorySelected} />
      <Text className={classes.label} color={isCategorySelected ? Texts.base : Texts.tint1} size={Typography.t5} weight={FontWeights.SemiBold}>
        {category.attributes.Title}
      </Text>
    </View>
  );
};

export default CategoryBox;

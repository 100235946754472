import { useEffect } from 'react';
import './App.css';
import { DiscordSDK, RPCCloseCodes } from '@discord/embedded-app-sdk';
import { useAppStyles, useStyles } from 'AppStyles';
import { IDiscordAuthenthication, IUserAvatarResponse } from './types';
import Routes from './routes/Routes';
import { dispatch } from 'store/external';
import authSlice from 'store/slices/auth';
import { getUserAvatar } from 'api/user/getUserAvatar';
import mainSlice from 'store/slices/main';
import { onAuthTokenUpdate, userAuthorization } from 'api/user/userAuthorization';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { View } from '@picsart/ds-components/View';

const discordSdk = new DiscordSDK(process.env.REACT_APP_DISCORD_CLIENT_ID);

function App() {
  useStyles();
  const classes = useAppStyles();
  const { width } = useWindowDimensions();

  useEffect(() => {
    async function setup() {
      // Wait for READY payload from the discord client
      await discordSdk.ready();
      // Pop open the OAuth permission modal and request for access to scopes listed in scope array below
      const { code } = await discordSdk.commands.authorize({
        client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
        response_type: 'code',
        state: '',
        prompt: 'none',
        scope: ['identify', 'applications.commands'],
      });

      // Retrieve an access_token from your application's server
      const response = await userAuthorization(code);
      if (response.status === 400) {
        discordSdk.close(RPCCloseCodes.CLOSE_ABNORMAL, 'User is not authorized');
        return;
      }

      const authentication: IDiscordAuthenthication = response.data;

      onAuthTokenUpdate((profile: any) => {
        dispatch(authSlice.actions.setUserData(profile));
        dispatch(authSlice.actions.setUserAccessToken(profile.access_token));
        dispatch(authSlice.actions.setUserRefreshToken(profile.refresh_token));
      });

      dispatch(authSlice.actions.setUserData(authentication));
      dispatch(authSlice.actions.setUserAccessToken(authentication.access_token));
      dispatch(authSlice.actions.setUserRefreshToken(authentication.refresh_token));

      const uploadedUserAvatar: IUserAvatarResponse = await getUserAvatar(authentication);

      dispatch(
        authSlice.actions.setUserAvatarUrl({
          url: uploadedUserAvatar.image_url,
          id: uploadedUserAvatar.sessionId,
        })
      );
      const { skus } = await discordSdk?.commands.getSkus();
      dispatch(mainSlice.actions.setDiscordSKUs(skus));

      dispatch(mainSlice.actions.setDiscordSDK(discordSdk));
    }

    setup().then(() => {});
  }, []);

  return (
    <>
      {width <= 320 && <View className={classes.smallSizeVIew} />}
      <Routes />
    </>
  );
}

export default App;

import { IAuthState } from 'types';

const initialState: IAuthState = {
  avatar: { url: null, id: null },
  user: null,
  access_token: null,
  refresh_token: null,
};

export default initialState;

import { Spacings } from '@picsart/ds-foundation/spacings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: Spacings.s24,
    padding: `${Spacings.s0} ${Spacings.s24}`,
  },
  image: {
    width: `calc(20% - ${Spacings.s24})`,
    height: 'auto',
  },
  imageList: {
    alignItems: 'stretch',
    justifyContent: 'space-between',
    gap: Spacings.s12,
    width: '860px',
  },
  imageView: {
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  scrollBox: {
    display: 'none',
  },

  [MEDIA_QUERY.LAPTOP]: {
    root: {
      gap: Spacings.s8,
      padding: Spacings.s0,
    },
    image: {
      width: `calc(20% - ${Spacings.s8})`,
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    scrollBox: {
      display: 'block',
    },
    root: {
      display: 'none',
    },
  },
});

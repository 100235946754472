import { View } from '@picsart/ds-components/View';
import { useStyles } from './ConfirmationStyles';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { Checkbox } from '@picsart/ds-components/Checkbox';
import { ITypography, Typography } from '@picsart/ds-foundation/typography';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Link } from '@picsart/ds-components/Link';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { IReduxState } from 'types';
import { getIsTermsCheckedStatus } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { DEVICE_SCREEN_MAXIMUM_SIZES, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/constants';
import { getDiscordSDK } from 'store/slices/main/selectors';

const rulesConfirmationSelector = (state: IReduxState) => ({
  isTermsChecked: getIsTermsCheckedStatus(state),
  discordSDK: getDiscordSDK(state),
});

const RulesConfirmation = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t5);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setTextSize(Typography.t4);
    } else {
      setTextSize(Typography.t5);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  const { isTermsChecked, discordSDK } = useSelector(rulesConfirmationSelector);

  const handleOpenExternalUrl = (externalUrl: string) => {
    discordSDK?.commands.openExternalLink({ url: externalUrl });
  };

  return (
    <View className={classes.root}>
      <Checkbox isChecked={isTermsChecked} onChange={() => dispatch(generationFlowSlice.actions.setIsTermsCheckedStatus(!isTermsChecked))} className={classes.checkbox} />
      <View className={classes.textContainer}>
        <Text weight={FontWeights.Regular} size={textSize} align={TypographyHorizontalAlign.Center}>
          I confirm that I am 18 years of age or older and have read and agree to the
          <Link onClick={() => handleOpenExternalUrl(TERMS_OF_USE_URL)} target='_blank' weight={FontWeights.Bold} size={textSize} className={classes.url}>
            {' '}
            Terms and Conditions{' '}
          </Link>
          of AI Avatar,
        </Text>
        <Text weight={FontWeights.Regular} size={textSize} align={TypographyHorizontalAlign.Center}>
          and consent to the use of my biometric data as outlined in Picsart's
          <Link onClick={() => handleOpenExternalUrl(PRIVACY_POLICY_URL)} target='_blank' weight={FontWeights.Bold} size={textSize} className={classes.url}>
            {' '}
            Privacy Policy
          </Link>
        </Text>
      </View>
    </View>
  );
};

export default RulesConfirmation;

import { Popup } from '@picsart/ds-components/Popup';
import { BottomSheet } from '@picsart/ds-components/BottomSheet';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useStyles } from './UploadPopUpStyles';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}

const UploadWrapper = ({ children, isOpen, handleClose }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useWindowDimensions();
  const classes = useStyles();

  useEffect(() => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return isMobile ? (
    <BottomSheet className={classes.bottomSheet} title='' zIndex={3} customHeight={490} isOpen={isOpen} onClose={handleClose} isFullWidth isSafeAreaExist={false} hideHandleLine reverseIcons>
      {children}
    </BottomSheet>
  ) : (
    <Popup isShown={isOpen} onClose={handleClose} zIndex={2}>
      {children}
    </Popup>
  );
};

export default UploadWrapper;

import { useEffect, useState } from 'react';
import { View } from '@picsart/ds-components/View';
import { Typography } from '@picsart/ds-foundation/typography';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { Image } from '@picsart/ds-components/Image';
import { Skeleton } from '@picsart/ds-components/Skeleton';
import { Backgrounds } from '@picsart/ds-foundation/colors/backgrounds';
import { IconWarningTriangleOutline } from '@picsart/ds-foundation/Icons/IconWarningTriangleOutline';
import { Statuses } from '@picsart/ds-foundation/colors/statuses';
import { useStyles } from './FreeAvatarItemStyles';
import { AvatarGenerationStatus, DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';
import ResultPreview from '../ResultPreview/ResultPreview';
import { getCDNURL } from 'utils/getCDNUrl';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { IHistoryImage } from 'types';
import MobileResultPreview from '../MobileResultPreview/MobileResultPreview';
import { Button, ButtonSizeSM, ButtonSkinMarket } from '@picsart/ds-components/Button';
import useDownload from 'hooks/useDownload';

interface Props {
  images: IHistoryImage[];
  created_at: string;
  status: AvatarGenerationStatus;
}

const FreeAvatarItem = ({ images, created_at, status }: Props) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [image, setImage] = useState<IHistoryImage | null>(null);
  const { width } = useWindowDimensions();
  const { download } = useDownload();

  const isGenerationDone = status === AvatarGenerationStatus.DONE;
  const isGenerationFailed = status === AvatarGenerationStatus.FAILED || status === AvatarGenerationStatus.FAILED_IMAGE;
  const isGenerationLoading =
    status === AvatarGenerationStatus.IN_PROGRESS ||
    status === AvatarGenerationStatus.ACCEPTED ||
    status === AvatarGenerationStatus.INIT;

  const classes = useStyles({ isZoomedIn: isGenerationDone ?? false });

  useEffect(() => {
    setImage(images[0]);
  }, [images]);

  useEffect(() => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, [width]);

  const handleDownload = () => {
    if (image) {
      download(image?.url);
    }
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const handlePreviewOpen = () => {
    if (isGenerationDone) {
      setIsPreviewOpen(true);
    }
  };

  return (
    <View flexDirection="column">
      {isPreviewOpen &&
        (isMobileDevice ? (
          <MobileResultPreview
            isOpen={isPreviewOpen}
            images={[image]}
            onClose={handlePreviewClose}
            choosenImageIndex={0}
          />
        ) : (
          <ResultPreview images={[image]} isOpen={isPreviewOpen} onClose={handlePreviewClose} choosenImageIndex={0} />
        ))}
      <View flexDirection="column" className={classes.freeAvatarGridItem} onClick={handlePreviewOpen}>
        {isGenerationLoading ? (
          <View width="100%" position="relative" className={classes.skeletonWrapper}>
            <Skeleton width="100%" height="100%" />
          </View>
        ) : isGenerationFailed ? (
          <View
            width="100%"
            position="relative"
            className={classes.skeletonWrapper}
            justifyContent="center"
            alignItems="center"
            backgroundColor={Backgrounds.tint1}
          >
            <IconWarningTriangleOutline fill={Statuses.error.base.default} />
          </View>
        ) : (
          <Image src={image?.url ? `${getCDNURL(image)}?type=webp&to=crop&r=512` : null} />
        )}
        {isGenerationFailed || isGenerationLoading || (
          <View
            className={classes.downloadBox}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleDownload();
            }}
          >
            <Text
              weight={FontWeights.SemiBold}
              size={Typography.t5}
              align={TypographyHorizontalAlign.Center}
              color={'white'}
            >
              Download
            </Text>
          </View>
        )}
      </View>
      <View width="100%" justifyContent="center">
        {isGenerationFailed || isGenerationLoading || (
          <Button
            className={classes.downloadButton}
            size={ButtonSizeSM}
            skin={ButtonSkinMarket}
            isFullWidth
            onClick={handleDownload}
          >
            Download
          </Button>
        )}
      </View>
    </View>
  );
};

export default FreeAvatarItem;

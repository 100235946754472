import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

const getGeneratedStyle = async (transaction_id: string) => {
  try {
    const response = await axios.get(`${DISCORD_PROXY_BASE_URL}/api/v2/gen/style-pack?task=${transaction_id}`);
    return response;
  } catch (error) {
    console.error('Failed to fetch categories', error);
    return error;
  }
};

export default getGeneratedStyle;

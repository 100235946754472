import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { useStyles } from './UploadPopUpStyles';
import { IGenerationPostData, IReduxState, IUploadedImage } from 'types';
import { getGenerationFlowType, getSelectedStyles, getUploadedImage } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Typography } from '@picsart/ds-foundation/typography';
import { ImageFrame } from '@picsart/ds-components/ImageFrame';
import { GENERATION_TYPES, GenerationFlowTypesEnum, ImageFaceCheckStatusEnum, ImageFaceCheckStepsEnum } from 'constants/constants';
import { useEffect, useState } from 'react';
import { getImageFaceCheckStatus } from 'api/server/getImageFaceCheckStatus';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import FaceCheckLoader from './FaceCheckLoader';
import UploadActions from './UploadActions';
import { uploadImage } from 'api/server/uploadImage';
import { getCDNURL } from 'utils/getCDNUrl';
import { postGenerationData } from 'api/server/postGenerationData';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'api/user/getUser';
import authSlice from 'store/slices/auth';
import historySlice from 'store/slices/history';
import { getDiscordSDK, getDiscordSKUs } from 'store/slices/main/selectors';
import UploadWrapper from './UploadWrapper';
import { postLogs } from '../../api/server/postLogs';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const uploadPopUpSelector = (state: IReduxState) => ({
  uploadedImage: getUploadedImage(state),
  selectedStyles: getSelectedStyles(state),
  flowType: getGenerationFlowType(state),
  discordSDK: getDiscordSDK(state),
  skus: getDiscordSKUs(state),
});

const UploadPopUp = ({ isOpen, handleClose }: Props) => {
  const navigate = useNavigate();

  const { uploadedImage, selectedStyles, flowType, skus, discordSDK } = useSelector(uploadPopUpSelector);

  const [faceCheckStatus, setFaceCheckStatus] = useState<ImageFaceCheckStepsEnum>(ImageFaceCheckStepsEnum.NULL);
  const [uploadLoadingIsVisisble, setUploadLoadingVisibility] = useState<boolean>(false);
  const [uploadErrorIsVisible, setUploadErrorVisibility] = useState<boolean>(false);

  const handleFaceCheck = async () => {
    if (uploadedImage && !uploadedImage.faceCheckStatus && uploadedImage.url) {
      setFaceCheckStatus(ImageFaceCheckStepsEnum.LOADING);

      const faceCheckResponse: ImageFaceCheckStatusEnum = await getImageFaceCheckStatus(uploadedImage?.url);

      dispatch(generationFlowSlice.actions.setUploadedImageFaceCheckStatus(faceCheckResponse));

      if (faceCheckResponse === ImageFaceCheckStatusEnum.VALID) {
        setFaceCheckStatus(ImageFaceCheckStepsEnum.VALID);

        setTimeout(() => {
          setFaceCheckStatus(ImageFaceCheckStepsEnum.NULL);
        }, 1500);
      } else {
        setFaceCheckStatus(ImageFaceCheckStepsEnum.NOT_VALID);
      }
    }
  };

  const handleFileUpload = async (file: File) => {
    if (file) {
      setUploadLoadingVisibility(true);
      setUploadErrorVisibility(false);
      setFaceCheckStatus(ImageFaceCheckStepsEnum.NULL);
      const uploadedImageResponse: IUploadedImage = await uploadImage(file);

      if (uploadedImageResponse.success) {
        dispatch(
          generationFlowSlice.actions.setUploadedImage({
            url: uploadedImageResponse.url,
            id: uploadedImageResponse.id,
            faceCheckStatus: null,
          })
        );
        setFaceCheckStatus(ImageFaceCheckStepsEnum.LOADING);
        setUploadLoadingVisibility(false);
      } else {
        setUploadErrorVisibility(true);
        setUploadLoadingVisibility(false);
      }
    }
  };

  useEffect(() => {
    handleFaceCheck();
    // eslint-disable-next-line
  }, [uploadedImage]);

  const classes = useStyles({
    image_url: uploadLoadingIsVisisble ? null : `${getCDNURL(uploadedImage)}`,
  });

  const handleStartGeneration = async () => {
    const data: IGenerationPostData = {
      type: flowType === GenerationFlowTypesEnum.AVATAR_GENERATION_WITH_PAYMENT ? GENERATION_TYPES.WITH_PAYMENT : GENERATION_TYPES.FREE,
      images: [uploadedImage.url],
      styles: selectedStyles,
      entitlement: null,
    };

    await postLogs({ handleStartGeneration: data, sku: skus[0], flowType: flowType });

    if (flowType === GenerationFlowTypesEnum.AVATAR_GENERATION_WITH_PAYMENT) {
      try {
        const purchaseResponse = await discordSDK?.commands.startPurchase({ sku_id: skus[0].id });
        await postLogs({ purchaseResponse: purchaseResponse, sku: skus[0] });

        if (purchaseResponse && purchaseResponse[0].id) {
          data.entitlement = purchaseResponse as any;
          const response = await postGenerationData(data);
          if (response.status === 200 && response.data?.styles?.length > 0) {
            navigate('/my-avatars');
            handleClose();
          }
        }
      } catch (error: any) {
        await postLogs({ 'purchaseResponse error': JSON.stringify(error) });
        console.log(JSON.stringify(error));
      }
    } else if (flowType === GenerationFlowTypesEnum.FREE_AVATAR_GENERATION) {
      const response = await postGenerationData(data);

      if (response.status === 200 && response.data?.styles?.length > 0) {
        const response = await getUser();
        if (response.status === 200) {
          const updatedUserData = response.data;
          dispatch(authSlice.actions.setUserData(updatedUserData));
          dispatch(authSlice.actions.setUserAccessToken(updatedUserData.access_token));
          dispatch(authSlice.actions.setUserRefreshToken(updatedUserData.refresh_token));
          dispatch(historySlice.actions.setIsComingFromFreeAvatarCreation(true));
          navigate('/my-avatars');
          handleClose();
        }
      }
    }
  };

  return (
    <UploadWrapper isOpen={isOpen} handleClose={handleClose}>
      <View className={classes.root}>
        <Text size={Typography.t6} weight={FontWeights.SemiBold}>
          Upload your image
        </Text>

        <ImageFrame isActive={faceCheckStatus === ImageFaceCheckStepsEnum.NULL} className={classes.uploadedImageBox}>
          {faceCheckStatus !== ImageFaceCheckStepsEnum.NULL && <FaceCheckLoader status={faceCheckStatus} />}
          {uploadLoadingIsVisisble && <FaceCheckLoader status={ImageFaceCheckStepsEnum.LOADING} />}
        </ImageFrame>
        <UploadActions
          isLoadingVisible={uploadLoadingIsVisisble}
          isErrorVisible={uploadErrorIsVisible}
          status={faceCheckStatus}
          handleImageReplace={(file: File) => handleFileUpload(file)}
          handleConfirm={handleStartGeneration}
          generationFlow={flowType}
        />
      </View>
    </UploadWrapper>
  );
};

export default UploadPopUp;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ExploreAndCreateWrapper from 'containers/ExploreAndCreate/ExploreAndCreateWrapper';
import HistoryWrapper from 'containers/History/HistoryWrapper';
import HowItWorksWrapper from 'containers/HowItWorks/HowItWorksWrapper';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ExploreAndCreateWrapper />,
  },
  {
    path: 'my-avatars',
    element: <HistoryWrapper />,
  },

  {
    path: 'how-it-works',
    element: <HowItWorksWrapper />,
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;

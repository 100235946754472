import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const fetchFreeStyle = async () => {
  try {
    const response = await axios.get(`${DISCORD_PROXY_BASE_URL}/cms/api/discord-free-gen-styles`, {
      params: {
        'populate[image][populate]': '*',
        'populate[prompt_list][populate]': '*',
        'populate[model_type][populate]': '*',
        populate: '*',
      },
      headers: {
        'Content-Security-Policy': "connect-src 'self'",
      },
    });
    return response;
  } catch (error) {
    console.error('Failed to fetch styles', error);
    return error;
  }
};

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';
import { FreeAvatarGenerationFlowPagesEnum, GenerationFlowPagesEnum, GenerationFlowTypesEnum, ImageFaceCheckStatusEnum } from 'constants/constants';
import { ICategoryType, IFreeStyleType, IGenerationFlowImage, ISelectedStyle, IStyleType } from 'types';

const generationFlowSlice = createSlice({
  name: 'generationFlow',
  initialState,
  reducers: {
    setCurrentPage(state, { payload }: PayloadAction<GenerationFlowPagesEnum>) {
      state.currentPage = payload;
    },
    setCategories(state, { payload }: PayloadAction<ICategoryType[]>) {
      state.categories = payload;
    },
    setFreeAvatarCurrentPage(state, { payload }: PayloadAction<FreeAvatarGenerationFlowPagesEnum>) {
      state.freeAvatarGenerationCurrentPage = payload;
    },
    setGenerationFlowType(state, { payload }: PayloadAction<GenerationFlowTypesEnum>) {
      state.flowType = payload;
    },
    setSelectedCategory(state, { payload }: PayloadAction<string>) {
      state.selectedCategory = payload;
    },
    setFreeAvatarCategory(state, { payload }: PayloadAction<string>) {
      state.freeAvatarCategory = payload;
    },
    setFreeAvatarStyle(state, { payload }: PayloadAction<IFreeStyleType>) {
      state.freeAvatarStyle = payload;
    },
    setStyles(state, { payload }: PayloadAction<IStyleType[]>) {
      state.styles = payload;
    },
    setSelectedStyles(state, { payload }: PayloadAction<ISelectedStyle[]>) {
      state.selectedStyles = payload;
    },
    setIsTermsCheckedStatus(state, { payload }: PayloadAction<boolean>) {
      state.isTermsChecked = payload;
    },
    setUploadedImage(state, { payload }: PayloadAction<IGenerationFlowImage>) {
      state.uploadedImage = payload;
    },
    setUploadedImageFaceCheckStatus(state, { payload }: PayloadAction<ImageFaceCheckStatusEnum>) {
      state.uploadedImage.faceCheckStatus = payload;
    },
  },
});

export default generationFlowSlice;

import History from 'components/History/History';
import StandardWrapper from 'containers/StandardWrapper';
import { useEffect } from 'react';
import { windowScrollToTop } from 'utils/windowScrollToTop';

const HistoryWrapper = () => {
  useEffect(() => {
    windowScrollToTop(window);
  }, []);

  return <StandardWrapper content={<History />} />;
};

export default HistoryWrapper;

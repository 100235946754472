import { Timings } from '@picsart/ds-foundation/timings';
import { createUseStyles } from 'react-jss';
import { Radius } from '@picsart/ds-foundation/radius';
import { MEDIA_QUERY } from 'constants/constants';

export const useStyles = createUseStyles({
  leftChevronWrapper: {
    height: '48px',
    width: '48px',
    transform: `translateY(-50%)`,
    left: -72,
    cursor: 'pointer',
    transition: Timings.Default,
    '&:hover': {
      transform: 'scale(.99) translateY(-50%)',
    },
  },
  rightChevronWrapper: {
    height: '48px',
    width: '48px',
    transform: `translateY(-50%)`,
    right: -72,
    cursor: 'pointer',
    transition: Timings.Default,
    '&:hover': {
      transform: 'scale(.99) translateY(-50%)',
    },
  },
  previewImage: {
    borderRadius: Radius.r16,
    pointerEvents: 'none',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    height: '500px',
    width: '500px',
  },
  [MEDIA_QUERY.TABLET]: {
    previewImage: {
      borderRadius: Radius.r16,
      pointerEvents: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      height: '350px',
      width: '350px',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    previewImage: {
      borderRadius: Radius.r16,
      pointerEvents: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      aspectRatio: '1/1',
      width: '100%',
      height: 'auto',
    },
    leftChevronWrapper: {
      height: '32px',
      width: '32px',
      transform: `translateY(-50%)`,
      left: 24,
      cursor: 'pointer',
      transition: Timings.Default,
      '&:hover': {
        transform: 'scale(.99) translateY(-50%)',
      },
    },
    rightChevronWrapper: {
      height: '32px',
      width: '32px',
      transform: `translateY(-50%)`,
      right: 24,
      cursor: 'pointer',
      transition: Timings.Default,
      '&:hover': {
        transform: 'scale(.99) translateY(-50%)',
      },
    },
  },
});

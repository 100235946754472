import { TypographyHorizontalAlign, Text } from '@picsart/ds-components/Text';
import { Radius } from '@picsart/ds-foundation/radius';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { Backgrounds } from '@picsart/ds-foundation/colors/backgrounds';
import { Statuses } from '@picsart/ds-foundation/colors/statuses';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Typography } from '@picsart/ds-foundation/typography';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { View } from '@picsart/ds-components/View';
import { IconWarningCircleOutline } from '@picsart/ds-foundation/Icons/IconWarningCircleOutline';
import { useStyles } from './FailedGenerationMessageStyles';
import { useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';

interface Props {
  title: string;
  description: string;
}

const FailedGenerationMessage = ({ title, description }: Props) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const classes = useStyles();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, [width]);

  return (
    <View
      className={classes.warningWrapper}
      borderRadius={Radius.r16}
      marginBlockStart={Spacings.s16}
      position='absolute'
      flexDirection='column'
      zIndex={1}
      backgroundColor={Backgrounds.panel}
      justifyContent='center'
      alignItems='center'
    >
      <View width='100%' maxWidth={320} justifyContent='center' alignItems='center' flexDirection='column'>
        <View backgroundColor='white' borderRadius={Radius.circle} height={56} width={56} justifyContent='center' alignItems='center'>
          <IconWarningCircleOutline fill={Statuses.error.base.default} height={24} width={24} />
        </View>
        <View marginBlockStart={Spacings.s16} width='100%' justifyContent='center'>
          <Text color={Statuses.error.base.default} weight={FontWeights.SemiBold} size={isMobileDevice ? Typography.t4 : Typography.t5}>
            {title}
          </Text>
        </View>
        <View marginBlockStart={Spacings.s8} width='100%' justifyContent='center'>
          <Text size={isMobileDevice ? Typography.t4 : Typography.t3} weight={FontWeights.Medium} color={Texts.tint1} align={TypographyHorizontalAlign.Center}>
            {description}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default FailedGenerationMessage;

import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const userAuthorization = async (code: string) => {
  try {
    const response:any = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/auth/user`, { code });
    if (response.status >= 200 && response.status < 300) {
      if (response.data.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
        sessionStorage.setItem("at", response.data.access_token);
        sessionStorage.setItem("rt", response.data.refresh_token);
      }
    }
    return response;
  } catch (error) {
    console.error('Failed to authorize user', error);
    return error;
  }
};

export const onAuthTokenUpdate = (onTokenUpdate:any) => {
  axios.interceptors.response.use(response => {
    return response
  }, async error => {
    const status = error.response ? error.response.status : null
    const url = error.config.url;
    if (url.includes("/api/v2/") && status === 401) {
      console.log("INTERCEPTOR ERROR TRIGGER : request URL", url);
      let rt = sessionStorage.getItem("rt");
      if (rt) {
        try {
          console.log("current refresh token", rt);
          const response: any = await axios.post(
              `${DISCORD_PROXY_BASE_URL}/api/v2/auth/refresh`,
              {refreshToken: rt}
          );
          console.log("current refresh request", response.status, response.data);

          if (response.status >= 200 && response.status < 300) {

            sessionStorage.setItem("at", response.data.access_token);
            sessionStorage.setItem("rt", response.data.refresh_token);

            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
            error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;

            if (typeof onTokenUpdate == "function") {
              onTokenUpdate(response.data);
            }

            return axios(error.config);
          }
        } catch (refreshError) {
          console.error('AUTO REFRESH TOKEN : Token refresh failed:', refreshError);
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(error);
  });
}


// dispatch(authSlice.actions.setUserAccessToken('TOKEN'));

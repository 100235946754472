import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const getHistory = async () => {
  try {
    const response = await axios.get(`${DISCORD_PROXY_BASE_URL}/api/v2/gen/style-pack-history`);
    return response;
  } catch (error) {
    console.error('Failed to fetch categories', error);
    return error;
  }
};

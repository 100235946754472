import axios from 'axios';
import {
  DISCORD_PROXY_BASE_URL,
  ImageUploadStatusEnum,
} from 'constants/constants';
import { IDiscordAuthenthication } from 'types';

export const getUserAvatar = async (user: IDiscordAuthenthication) => {
  if (user != null) {
    const discordImageUrl = `${process.env.REACT_APP_DISCORD_CDN_URL}/avatars/${user.uid}/${user.avatar}.webp`;

    try {
      const response = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/media/image-from-url`, {
        image_url: discordImageUrl,
      });
      if (response.status === 200) {
        return {
          status: ImageUploadStatusEnum.SUCCESS,
          image_url: response.data.url,
          sessionId: response.data.sessionID,
        };
      }
      return {
        status: ImageUploadStatusEnum.UPLOAD_FAILED,
        image_url: discordImageUrl,
        sessionId: null,
      };
    } catch (error) {
      console.error('Failed to upload image from url', error);
      return {
        status: ImageUploadStatusEnum.UPLOAD_FAILED,
        image_url: discordImageUrl,
        sessionId: null,
      };
    }
  }
  return {
    status: ImageUploadStatusEnum.USER_NOT_FOUND,
    image_url: null,
    sessionId: null,
  };
};

import { useState, useEffect } from 'react';
import { BottomSheet } from '@picsart/ds-components/BottomSheet';
import { Image } from '@picsart/ds-components/Image';
import { View } from '@picsart/ds-components/View';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { useStyles } from './MobileResultPreviewStyles';
import { Button, ButtonSizeMD, ButtonSkinTertiary } from '@picsart/ds-components/Button';
import { IconChevronRightSmall } from '@picsart/ds-foundation/Icons/IconChevronRightSmall';
import { Statuses } from '@picsart/ds-foundation/colors/statuses';
import { Radius } from '@picsart/ds-foundation/radius';
import { IconChevronLeftSmall } from '@picsart/ds-foundation/Icons/IconChevronLeftSmall';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getCDNURL } from 'utils/getCDNUrl';
import { IHistoryImage } from 'types';
import useDownload from 'hooks/useDownload';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  choosenImageIndex: number;
  images: IHistoryImage[];
}

const MobileResultPreview = ({ isOpen, choosenImageIndex, onClose, images }: Props) => {
  const [currentImage, setCurrentImage] = useState(choosenImageIndex);

  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { download } = useDownload();

  const handlePrevious = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(prev => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(prev => prev + 1);
    }
  };

  const handleDownload = () => {
    download(images[currentImage].url);
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePrevious();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    //eslint-disable-next-line
  }, [currentImage]);

  return (
    <BottomSheet
      title=""
      zIndex={3}
      customHeight={width + 130}
      isOpen={isOpen}
      onClose={onClose}
      isFullWidth
      isSafeAreaExist={false}
      hideHandleLine
      reverseIcons
    >
      <View flexDirection={'column'} justifyContent="center">
        {images.length > 1 && (
          <View
            className={classes.leftChevronWrapper}
            borderRadius={Radius.circle}
            backgroundColor="white"
            justifyContent="center"
            position="absolute"
            alignItems="center"
            marginInlineEnd={Spacings.s24}
            onClick={handlePrevious}
          >
            <IconChevronLeftSmall fill={Statuses.positive.text.default} />
          </View>
        )}
        <Image className={classes.previewImage} src={`${getCDNURL(images[currentImage])}?type=webp&to=crop&r=512`} />
        {images.length > 1 && (
          <View
            className={classes.rightChevronWrapper}
            borderRadius={Radius.circle}
            backgroundColor="white"
            justifyContent="center"
            position="absolute"
            alignItems="center"
            marginInlineStart={Spacings.s24}
            onClick={handleNext}
          >
            <IconChevronRightSmall fill={Statuses.positive.text.default} />
          </View>
        )}
        <View marginBlockStart={Spacings.s24} gap={Spacings.s8} width={'100%'} justifyContent="center">
          <Button size={ButtonSizeMD} skin={ButtonSkinTertiary} isFullWidth onClick={handleDownload}>
            Download
          </Button>
        </View>
      </View>
    </BottomSheet>
  );
};

export default MobileResultPreview;

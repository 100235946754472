import { FontWeights } from '@picsart/ds-foundation/fonts';
import { ITypography, Typography } from '@picsart/ds-foundation/typography';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';

interface Props {
  content: string;
}

const Title = ({ content }: Props) => {
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t7);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setTextSize(Typography.t6);
    } else {
      setTextSize(Typography.t7);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  return (
    <Text size={textSize} weight={FontWeights.SemiBold} align={TypographyHorizontalAlign.Center}>
      {content}
    </Text>
  );
};

export default Title;

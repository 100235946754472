import { IReduxState } from 'types';

export const getGenerationFlowSlice = (state: IReduxState) => state.generationFlow;

export const getCurrentPage = (state: IReduxState) => getGenerationFlowSlice(state).currentPage;

export const getCategories = (state: IReduxState) => getGenerationFlowSlice(state).categories;

export const getSelectedCategory = (state: IReduxState) => getGenerationFlowSlice(state).selectedCategory;

export const getFreeAvatarCategory = (state: IReduxState) => getGenerationFlowSlice(state).freeAvatarCategory;

export const getFreeAvatarStyle = (state: IReduxState) => getGenerationFlowSlice(state).freeAvatarStyle;

export const getStyles = (state: IReduxState) => getGenerationFlowSlice(state).styles;

export const getSelectedStyles = (state: IReduxState) => getGenerationFlowSlice(state).selectedStyles;

export const getIsTermsCheckedStatus = (state: IReduxState) => getGenerationFlowSlice(state).isTermsChecked;

export const getUploadedImage = (state: IReduxState) => getGenerationFlowSlice(state).uploadedImage;

export const getFreeAvatarGenerationCurrentPage = (state: IReduxState) => getGenerationFlowSlice(state).freeAvatarGenerationCurrentPage;

export const getGenerationFlowType = (state: IReduxState) => getGenerationFlowSlice(state).flowType;

import { GenerationFlowPagesEnum } from 'constants/constants';
import { useSelector } from 'react-redux';
import { getCategories, getCurrentPage, getFreeAvatarStyle, getStyles } from 'store/slices/generationFlow/selectors';
import { IReduxState } from 'types';
import GenerationFlowRoot from './GenerationFlowRoot/GenerationFlowRoot';
import { useEffect } from 'react';
import { fetchCategories } from 'api/cms/getCategories';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { fetchStyles } from 'api/cms/getStyles';
import { fetchFreeStyle } from 'api/cms/getFreeStyle';
import GenerationFlowRules from './GenerationFlowRules/GenerationFlowRules';
import { windowScrollToTop } from 'utils/windowScrollToTop';
import StandardWrapper from 'containers/StandardWrapper';
import { getUserData } from 'store/slices/auth/selectors';
import AuthLoading from 'components/AuthLoading/AuthLoading';

const exploreAndCreateSelector = (state: IReduxState) => ({
  currentPage: getCurrentPage(state),
  categories: getCategories(state),
  styles: getStyles(state),
  freeAvatarStyle: getFreeAvatarStyle(state),
  user: getUserData(state),
});

const ExploreAndCreateWrapper = () => {
  const { currentPage, categories, styles, freeAvatarStyle, user } = useSelector(exploreAndCreateSelector);

  const getCurrentPageContent = () => {
    switch (currentPage) {
      case GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES:
        return <GenerationFlowRoot />;
      case GenerationFlowPagesEnum.TERMS_OF_USE_AND_IMAGE_UPLOAD:
        return <GenerationFlowRules />;
    }
  };

  useEffect(() => {
    windowScrollToTop(window);
    getDefaultParams();
    // eslint-disable-next-line
  }, []);

  const getDefaultParams = async () => {
    if (!categories?.length) {
      const fetchedCategories = await fetchCategories();

      if (fetchedCategories.status === 200) {
        dispatch(generationFlowSlice.actions.setCategories(fetchedCategories.data.data));
        dispatch(generationFlowSlice.actions.setSelectedCategory(fetchedCategories.data.data[0].attributes.key));
      } else {
        dispatch(generationFlowSlice.actions.setCategories(null));
      }
    }

    if (!styles?.length) {
      const fetchedStyles = await fetchStyles();

      if (fetchedStyles.status === 200) {
        dispatch(generationFlowSlice.actions.setStyles(fetchedStyles.data.data));
      } else {
        dispatch(generationFlowSlice.actions.setStyles(null));
      }
    }
    if (!freeAvatarStyle) {
      const fetchedFreeStyle = await fetchFreeStyle();

      if (fetchedFreeStyle.status === 200) {
        dispatch(generationFlowSlice.actions.setFreeAvatarStyle(fetchedFreeStyle.data.data[0]));
      } else {
        dispatch(generationFlowSlice.actions.setStyles(null));
      }
    }
  };

  return <StandardWrapper content={user ? getCurrentPageContent() : <AuthLoading />} />;
};

export default ExploreAndCreateWrapper;

import { Timings } from '@picsart/ds-foundation/timings';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { Backgrounds } from '@picsart/ds-foundation/colors/backgrounds';

export const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
    height: '100%',
    width: '85%',
    maxWidth: '360px',
    transition: Timings.Default,
    padding: `${Spacings.s24} ${Spacings.s40}`,
    gap: Spacings.s24,
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: Backgrounds.base,
    zIndex: 3,
  },
  root_hidden: {
    left: '-85%',
  },
  closeButton: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  navigationItems: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: Spacings.s8,
    width: '100%',
    cursor: 'pointer',
  },
  navigationItemBox: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: Spacings.s12,
  },
  navigationItemTitle: {
    fontWeight: FontWeights.SemiBold,
    color: Texts.tint1,
  },

  [MEDIA_QUERY.MOBILE]: {
    root: {
      maxWidth: '300px',
      padding: `${Spacings.s24} ${Spacings.s24}`,
    },
  },
});

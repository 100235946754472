import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { dispatch } from 'store/external';
import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { Typography } from '@picsart/ds-foundation/typography';
import { Button, ButtonSizeLG } from '@picsart/ds-components/Button';
import { fetchCategories } from 'api/cms/getCategories';
import generationFlowSlice from 'store/slices/generationFlow';
import { getCategories, getFreeAvatarCategory, getFreeAvatarStyle } from 'store/slices/generationFlow/selectors';
import { FreeAvatarGenerationPopUpContent, GenerationFlowPagesEnum, GenerationFlowTypesEnum } from 'constants/constants';
import CategoryBox from 'components/CategoryBox/CategoryBox';
import { useStyles } from './FreeAvatarCategorySelectionStyles';
import { ICategoryType, IFreeStylePrompt, IReduxState } from 'types';

const genderSelectionSelector = (state: IReduxState) => ({
  categories: getCategories(state),
  freeAvatarCategory: getFreeAvatarCategory(state),
  freeAvatarStyle: getFreeAvatarStyle(state),
});

interface Props {
  handleClosePopUp: () => void;
}

const FreeAvatarCategorySelection = ({ handleClosePopUp }: Props) => {
  const classes = useStyles();
  const { categories, freeAvatarCategory, freeAvatarStyle } = useSelector(genderSelectionSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const isCategorySelected = (category: ICategoryType) => {
    return freeAvatarCategory === category.attributes.key;
  };

  const handleSelectCategory = (category: ICategoryType) => {
    dispatch(generationFlowSlice.actions.setFreeAvatarCategory(category.attributes.key));
  };

  const getPromptListItemByCategory = (category: string) => {
    if (category !== null) {
      return freeAvatarStyle.attributes.prompt_list.find((promptItem: IFreeStylePrompt) => promptItem.type.data.attributes.key === freeAvatarCategory);
    }
    return null;
  };

  const getDefaultParams = async () => {
    if (!categories?.length) {
      const fetchedCategories = await fetchCategories();

      if (fetchedCategories.status === 200) {
        dispatch(generationFlowSlice.actions.setCategories(fetchedCategories.data.data));
        dispatch(generationFlowSlice.actions.setFreeAvatarCategory(fetchedCategories.data.data[0].attributes.key));
      } else {
        dispatch(generationFlowSlice.actions.setCategories(null));
      }
    } else {
      dispatch(generationFlowSlice.actions.setFreeAvatarCategory(categories[0].attributes.key));
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const selectedPrompt = getPromptListItemByCategory(freeAvatarCategory);

    dispatch(
      generationFlowSlice.actions.setSelectedStyles([
        {
          id: selectedPrompt.id,
          title: selectedPrompt.title,
          prompt: selectedPrompt.prompt,
          negative_prompt: selectedPrompt.negative_prompt,
          model: freeAvatarStyle.attributes.model_type.data.attributes.Key,
        },
      ])
    );

    dispatch(generationFlowSlice.actions.setGenerationFlowType(GenerationFlowTypesEnum.FREE_AVATAR_GENERATION));
    if (location.pathname === '/') {
      dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.TERMS_OF_USE_AND_IMAGE_UPLOAD));
    } else {
      dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.TERMS_OF_USE_AND_IMAGE_UPLOAD));
      navigate('/');
    }
    handleClosePopUp();
  };

  useEffect(() => {
    getDefaultParams();
    //eslint-disable-next-line
  }, []);

  return (
    <View className={classes.root}>
      <Text size={Typography.t6} className={classes.title}>
        {FreeAvatarGenerationPopUpContent.categories.title}
      </Text>
      <View className={classes.categoryList}>
        {categories &&
          categories.map((category: ICategoryType) => (
            <CategoryBox category={category} key={category.id} isCategorySelected={isCategorySelected(category)} handleCategorySelect={() => handleSelectCategory(category)} />
          ))}
      </View>
      <Button isFullWidth size={ButtonSizeLG} onClick={handleButtonClick}>
        {FreeAvatarGenerationPopUpContent.categories.buttonTitle}
      </Button>
    </View>
  );
};

export default FreeAvatarCategorySelection;

import { Overlay } from '@picsart/ds-components/Overlay';
import { useStyles } from './MobileNavigationStyles';
import { View } from '@picsart/ds-components/View';
import { IconCrossCircleOutline } from '@picsart/ds-foundation/Icons/IconCrossCircleOutline';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { GenerationFlowPagesEnum, NavigationItems } from 'constants/constants';
import { INavigationItemsType, IReduxState } from 'types';
import { Text } from '@picsart/ds-components/Text';
import { Typography } from '@picsart/ds-foundation/typography';
import { useNavigate } from 'react-router-dom';
import { IconChevronRightLarge } from '@picsart/ds-foundation/Icons/IconChevronRightLarge';
import { getMobileNavigationOpenStatus } from 'store/slices/main/selectors';
import { useSelector } from 'react-redux';
import { dispatch } from 'store/external';
import mainSlice from 'store/slices/main';
import generationFlowSlice from 'store/slices/generationFlow';

const mobileNavigationSelector = (state: IReduxState) => ({
  mobileNavigationOpenStatus: getMobileNavigationOpenStatus(state),
});

const MobileNavigation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { mobileNavigationOpenStatus } = useSelector(mobileNavigationSelector);

  const handleClose = () => dispatch(mainSlice.actions.setMobileNavigationStatus(false));

  const handleNavigationClick = (navigationItemUrl: string) => {
    if (navigationItemUrl === '/') {
      dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    }
    navigate(navigationItemUrl);
    handleClose();
  };

  return (
    <>
      {mobileNavigationOpenStatus && <Overlay zIndex={2} closeCallback={handleClose} />}
      <View className={`${classes.root} ${!mobileNavigationOpenStatus && classes.root_hidden}`}>
        <IconCrossCircleOutline fill={Texts.tint1} onClick={handleClose} className={classes.closeButton} />
        <View className={classes.navigationItems}>
          {NavigationItems.map((navigationItem: INavigationItemsType) => (
            <View className={classes.navigationItemBox} key={navigationItem.title} onClick={() => handleNavigationClick(navigationItem.url)}>
              <Text key={navigationItem.url} className={classes.navigationItemTitle} size={Typography.t5}>
                {navigationItem.title}
              </Text>
              <IconChevronRightLarge fill={Texts.tint1} />
            </View>
          ))}
        </View>
      </View>
    </>
  );
};

export default MobileNavigation;

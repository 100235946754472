import { Spacings } from '@picsart/ds-foundation/spacings';
import { Timings } from '@picsart/ds-foundation/timings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textContent: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: Spacings.s24,
    marginBottom: Spacings.s24,
  },
  description: {
    maxWidth: '70%',
  },
  buttonBox: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    padding: Spacings.s16,
    background: 'linear-gradient(#ffffff00, #ffffff)',
    width: '100%',
    position: 'sticky',
    bottom: Spacings.s0,
    left: Spacings.s0,
    right: Spacings.s0,
  },
  button: {
    minWidth: '220px !important',
    transition: Timings.Default,
  },
  [MEDIA_QUERY.MOBILE]: {
    textContent: {
      gap: Spacings.s16,
      marginBottom: Spacings.s16,
    },
    description: {
      maxWidth: '100%',
    },
    buttonBox: {
      display: 'flex',
    },
  },
});

import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const fetchStyles = async () => {
  try {
    const response = await axios.get(`${DISCORD_PROXY_BASE_URL}/cms/api/discord-prompt-styles`, {
      params: {
        sort: ['rank:asc', 'id:asc'],
        populate: '*',
      },
      headers: {
        'Content-Security-Policy': "connect-src 'self'",
      },
    });
    return response;
  } catch (error) {
    console.error('Failed to fetch styles', error);
    return error;
  }
};

import { View } from '@picsart/ds-components/View';
import { useStyles } from './HowItWorksStyles';
import Title from 'components/Title/Title';
import { GenerationFlowPagesEnum, HowItWorksDefaultParams } from 'constants/constants';
import ShortDescription from 'components/ShortDescription/ShortDescription';
import Preview from './Preview/Preview';
import Steps from './Steps/Steps';
import { Button } from '@picsart/ds-components/Button';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';

const HowItWorks = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handlePageChange = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    navigate('/');
  };

  return (
    <View className={classes.root}>
      <View className={classes.textContent}>
        <Title content={HowItWorksDefaultParams.title} />
        <View className={classes.description}>
          <ShortDescription content={HowItWorksDefaultParams.description} />
        </View>
      </View>
      <Preview />
      <Steps />
      <View className={classes.buttonBox}>
        <Button className={classes.button} onClick={handlePageChange}>
          Create avatars
        </Button>
      </View>
    </View>
  );
};

export default HowItWorks;

import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const fetchCategories = async () => {
  try {
    console.log("--->", process.env.REACT_APP_DISCORD_CLIENT_ID)
    const response = await axios.get(`${DISCORD_PROXY_BASE_URL}/cms/api/product-types`);

    return response;
  } catch (error) {
    console.error('Failed to fetch categories', error);
    return error;
  }
};

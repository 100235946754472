import axios from 'axios';
import { DISCORD_PROXY_BASE_URL } from 'constants/constants';

export const postLogs = async (data:any) => {
    try {
        const response = await axios.post(`${DISCORD_PROXY_BASE_URL}/api/v2/gen/logs`, data);
        return response;
    } catch (error) {
        console.error('Failed to fetch categories', error);
        return error;
    }
};

import { Texts } from '@picsart/ds-foundation/colors/texts';
import { Backgrounds } from '@picsart/ds-foundation/colors/backgrounds';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Radius } from '@picsart/ds-foundation/radius';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  icon: {
    borderRadius: Radius.circle,
    background: Backgrounds.tint3,
    padding: Spacings.s12,
    marginBottom: Spacings.s16,
  },
  title: {
    fontWeight: FontWeights.SemiBold,
    textAlign: TypographyHorizontalAlign.Center,
    marginBottom: Spacings.s8,
  },
  descriptionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: Spacings.s16,
  },
  descriptionItem: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
    color: Texts.tint1,
  },
  button: {
    width: '220px',
  },
});

import { Spacings } from '@picsart/ds-foundation/spacings';
import { AUTH_LOADING_BACKGROUND } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    background: `url("${AUTH_LOADING_BACKGROUND}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: Spacings.s0,
    right: Spacings.s0,
    bottom: Spacings.s0,
    top: Spacings.s0,
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
});

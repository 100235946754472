import { View } from '@picsart/ds-components/View';
import { useStyles } from './GenerationFlowRulesStyles';
import RulesConfirmation from 'components/Rules/Confirmation/Confirmation';
import RulesVisualContent from 'components/Rules/VisualContent/VisualContent';
import ImageUpload from 'components/Rules/ImageUpload/ImageUpload';
import { Button, ButtonVariants } from '@picsart/ds-components/Button';
import { IconArrowLeftLarge } from '@picsart/ds-foundation/Icons/IconArrowLeftLarge';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { DEVICE_SCREEN_MAXIMUM_SIZES, GenerationFlowPagesEnum, GenerationFlowTypesEnum } from 'constants/constants';
import { IReduxState } from 'types';
import { getGenerationFlowType } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

const generationFlowRulesSelector = (state: IReduxState) => ({
  flowType: getGenerationFlowType(state),
});

const GenerationFlowRules = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { flowType } = useSelector(generationFlowRulesSelector);

  const [goBackButtonTitle, setGoBackButtonTitle] = useState<string | null>('Go back');

  const handleBackButtonClick = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    if (flowType === GenerationFlowTypesEnum.FREE_AVATAR_GENERATION) {
      dispatch(generationFlowSlice.actions.setSelectedStyles(null));
    }
  };

  const getButtonTitle = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setGoBackButtonTitle(null);
    } else {
      setGoBackButtonTitle('Go back');
    }
  };

  useEffect(() => {
    getButtonTitle();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.root}>
      <Button startIcon={IconArrowLeftLarge} variant={ButtonVariants.Text} className={classes.backButton} onClick={handleBackButtonClick}>
        {goBackButtonTitle}
      </Button>
      <RulesVisualContent />
      <RulesConfirmation />
      <ImageUpload />
    </View>
  );
};

export default GenerationFlowRules;

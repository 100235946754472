import { View } from '@picsart/ds-components/View';
import { useStyles } from '../PreviewStyles';
import { IHowItWorksStylesImages } from 'types';

interface Props {
  selectedStyleImageUrls: IHowItWorksStylesImages;
  style: string;
}

const PreviewImageList = ({ selectedStyleImageUrls, style }: Props) => {
  const classes = useStyles();

  return (
    <View className={classes.imageList}>
      {selectedStyleImageUrls.pathnames.map((imagePath: string) => (
        <View
          key={imagePath}
          className={classes.imageView}
          style={{
            backgroundImage: `url(${selectedStyleImageUrls.baseUrl}/${style}/${imagePath})`,
          }}
        />
      ))}
    </View>
  );
};

export default PreviewImageList;

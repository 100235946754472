import { View } from '@picsart/ds-components/View';
import { useStyles } from './FreeAvatarStyleStyles';
import { Text } from '@picsart/ds-components/Text';
import { Typography } from '@picsart/ds-foundation/typography';
import { FreeAvatarGenerationFlowPagesEnum, FreeAvatarGenerationPopUpContent } from 'constants/constants';
import { ImageFrame } from '@picsart/ds-components/ImageFrame';
import { Image } from '@picsart/ds-components/Image';
import { Button, ButtonSizeLG } from '@picsart/ds-components/Button';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { IReduxState } from 'types';
import { getFreeAvatarStyle } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';

const FreeAvatarStyleSelector = (state: IReduxState) => ({
  freeAvatarStyle: getFreeAvatarStyle(state),
});

const FreeAvatarStyle = () => {
  const classes = useStyles();

  const { freeAvatarStyle } = useSelector(FreeAvatarStyleSelector);

  const handleCreateAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(generationFlowSlice.actions.setFreeAvatarCurrentPage(FreeAvatarGenerationFlowPagesEnum.SELECT_CATEGORIES));
  };

  return (
    <View className={classes.root}>
      <Text size={Typography.t6} className={classes.title}>
        {FreeAvatarGenerationPopUpContent.intro.title}
      </Text>
      <Text size={Typography.t5} className={classes.description}>
        {FreeAvatarGenerationPopUpContent.intro.description}
      </Text>
      <ImageFrame className={classes.thumbnail}>
        <Image src={`/cms/${freeAvatarStyle.attributes.image.data.attributes.formats.thumbnail.url}`} alt={freeAvatarStyle.attributes.title} className={classes.thumbnailImage} />
      </ImageFrame>
      <Text size={Typography.t6} className={classes.thumbnailTitle}>
        {freeAvatarStyle.attributes.title}
      </Text>
      <Button isFullWidth size={ButtonSizeLG} onClick={handleCreateAvatarClick}>
        {FreeAvatarGenerationPopUpContent.intro.buttonTitle}
      </Button>
    </View>
  );
};

export default FreeAvatarStyle;
